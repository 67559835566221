// Fee Name Api
const TcbReport = '/tcb-report/reports/'
export const dealerReport = TcbReport + 'dealerreport'
export const beneficiaryReport = TcbReport + 'beneficiaryreport'
export const dailyStockReportHq = TcbReport + 'daily-stock-report-hq'

export const productWiseMonthlyReport = TcbReport + 'product-wise-monthly-report'
export const monthlyAllPurchasedProductReport = TcbReport + 'monthly-all-purchased-products'
export const productContractSupplyReport = TcbReport + 'product-supply-and-contract'
export const dailyStockReportRegion = TcbReport + 'daily-stock-report-region'
export const productWiseDailyReport = TcbReport + 'product-wise-daily-report'
export const allcationReport = TcbReport + 'allocation-report'
export const monthlyReportHqka = TcbReport + 'monthly-report-hq-ka'
export const monthlyReportHqkha = TcbReport + 'monthly-report-hq-kha'
export const paymentReport = TcbReport + 'payment-report'
export const yearlyReportRegion = TcbReport + 'yearly-report-region'
export const yearlyReportHq = TcbReport + 'yearly-report-hq'
export const monthlyStockReportRegionA = TcbReport + 'monthly-report-region-ka'
export const monthlyStockReportRegionB = TcbReport + 'monthly-report-region-kha'
export const productTransferReportDaily = TcbReport + 'product-transfer-report-daily'
export const productTransferReportTotal = TcbReport + 'product-transfer-report-total'

const existingDealer = '/manage-information/existing-dealer/'
export const getRegionDvision = existingDealer + 'region-based-division'

export const circularsApi = '/dashboard/circulars'

export const districtWiseInspectionReport = TcbReport + 'district-wise-inspection-report'
export const dealershipInspectionReport = TcbReport + 'dealership-inspection-report'
